import React, { useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "../../index.css";
import "./FooterLinks.css";

function CoronaSchutz() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="App">
      <Header />
      <main>
        <div className="FooterLinkPageWrapper">
          <h2 className="padding-20">Corona Schutzverordnung</h2>
          <h4 className="sub-FooterLinkPage-text padding-20">Vorbemerkung</h4>
          <p className="padding-20">
            Die Regelungen in diesem Konzept gelten zur Eindämmung des
            Coronavirus SARS-CoV-2. Maßgeblich ist die zum Zeitpunkt des
            Spieltages gültige Corona Bekämpfungsverordnung des Landes
            Rheinland-Pfalz. Dieses Konzept stellt eine Erweiterung dieser
            Verordnung dar. Personen, die nicht zur Einhaltung der unten
            aufgeführten Kriterien bereit sind, wird im Rahmen des Hausrechts
            der Zutritt verwehrt oder sie werden von der Sportstätte verwiesen.
            Dem Hygienebeauftragten bzw. seinen Delegierten ist Folge zu
            leisten. Die Regelungen durch die Corona-Bekämpfungsverordnung sowie
            die Regelungen dieses Konzeptes sind abhängig von der Warnstufe im
            Landkreis Altenkirchen. Die Warnstufen gelten wie folgt:
            <br />
            <br />
            <img
              src="https://corona.rlp.de/fileadmin/_processed_/b/f/csm_26._CoBVL_Warnsystem__2__e4b895d17a.png"
              width="300px"
              alt="Corona Warnstufen RLP"
            />
            <br />
            <br />
            Sollten mindestens zwei der drei Leitindikatoren in der
            obenstehenden Tabelle die dargestellten Wertebereiche erreichen,
            gilt die entsprechende Warnstufe. Vor den Spieltagen muss die
            Warnstufe entsprechend überprüft werden. Die Bedeutung der einzelnen
            Leitindikatoren ist in der jeweils geltenden
            Corona-Bekämpfungsverordnung nachzulesen. Die aktuellen Werte findet
            man auf <a href="www.lua.rlp.de">www.lua.rlp.de</a>.
          </p>
          <h4 className="sub-FooterLinkPage-text padding-20">Allgemeines</h4>
          <p className="padding-20">
            Für alle Teilnehmer gilt die 3G-Regel (Geimpft, Getestet, Genesen).
            Nicht immunisierte Personen müssen ein negatives Testergebnis auf
            eine Infektion mit SARS-CoV-2 vorweisen. Ein mitgebrachter
            Selbsttest vor Ort wird akzeptiert. <br />
            <br />
            Immunisierte Personen sind verpflichtet ihren entsprechenden
            Nachweis im Eingangsbereich vorzuweisen. <br />
            <br />
            Bei Krankheitssymptomen, die auf eine Infektion mit SARS-CoV-2
            hindeuten, ist der Zutritt zur Halle verboten. Es ist notwendig,
            Kontaktdaten von allen in den Hallen anwesenden Personen aufzunehmen
            und zu dokumentieren. Die aufzunehmenden Daten sind Name, Vorname,
            Adresse, Telefonnummer. Die Liste wird für die Dauer von einem Monat
            aufbewahrt und anschließend vernichtet. Die Daten werden
            ausschließlich auf Anfrage des zuständigen Gesundheitsamtes an das
            jeweilige Gesundheitsamt weitergeleitet. <br />
            <br />
            Darüber hinaus gelten beim Betreten der Sportstätte folgende Regeln:
            <br />
            <br />- Desinfizieren der Hände <br />- Abstand halten und möglichst
            kontaktfreier Umgang <br />- Körperliche Begrüßungsrituale sind zu
            unterlassen <br />- Hust- und Niesetikette sind zu beachten <br />-
            Vermeidung von Mischen von Mannschaften/Zuschauern auf engem Raum
            <br />
            <br />
            Sollte eine am Spieltag beteiligte Person im Nachgang
            Covid-19-ähnliche Symptome entwickeln oder ein positives
            Testergebnis aufweisen, sind alle anderen Beteiligten Mannschaften
            umgehend zu informieren.
          </p>
          <h4 className="sub-FooterLinkPage-text padding-20">Spielablauf</h4>
          <p className="padding-20">
            - In der Wettkampfzone (Innenraum) sind nur am Spielbetrieb
            beteiligte Personen zugelassen. <br />- Vor und nach dem Spiel sind
            unnötige Körperkontakte zu vermeiden. <br />- Offizielle (Trainer,
            Co-Trainer, etc.) auf der Bank achten auf den Mindestabstand. <br />
            - Der Schiedsrichter nutzt seine eigene Pfeife. <br />- Beim
            Seitenwechsel zwischen den Sätzen ist der Mindestabstand
            einzuhalten. <br />- Zwischen den Spielen werden der Anschreibetisch
            sowie die Spielerbänke desinfiziert.
          </p>
          <h4 className="sub-FooterLinkPage-text padding-20">Zuschauer</h4>
          <p className="padding-20">
            Zuschauer sind im Rahmen der geltenden Verordnung erlaubt. <br />
            <br />
            Sollte im Landkreis Altenkirchen die Warnstufe 1 gelten, so sind im
            Innenraum maximal 250 nicht-immunisierte Zuschauer zulässig. Bei
            Warnstufe 2 verringert sich die Personenzahl auf 100; bei Erreichen
            der Warnstufe 3 reduziert sich die Personenzahl auf 50. Über diesen
            Personenkreis hinaus können geimpfte oder genesene Personen
            teilnehmen. <br />
            <br />
            Grundsätzlich gelten bis zum Erreichen des Sitzplatzes die
            Verpflichtung zum Tragen einer medizinischen MNB sowie das
            Abstandsgebot. Sind während der Veranstaltung jedoch höchstens 25
            gleichzeitig nicht-immunisierte Personen anwesend, entfällt die
            Einhaltung des Abstandsgebots und der Maskenpflicht.
          </p>
          <h4 className="sub-FooterLinkPage-text padding-20">Catering</h4>
          <p className="padding-20">
            Es werden lediglich Getränke in Flaschen verkauft.
            <br />
            <br />
            Etzbach, Oktober 2021 <br />
            <br />- Der Vorstand -
          </p>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default CoronaSchutz;
