const initialState = {
  VorstandStore: [],
};

export function VorstandStore(state = initialState, action) {
  if (action.type === "setVorstand") {
    return { ...state, VorstandStore: action.payload };
  }
  return state;
}
