import React, { useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "../../index.css";
import "./FooterLinks.css";

function Satzung() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="App">
      <Header />
      <main>
        <div className="FooterLinkPageWrapper">
          <h2 className="padding-20">
            Satzung der Spiel- und Sportgemeinschaft (SSG) Etzbach e. V.
          </h2>
          <h4 className="sub-FooterLinkPage-text padding-20">
            § 1 – Name, Sitz und Zweck
          </h4>
          <p className="padding-40">
            <b>1.</b> Der am 29.09.1964 in Etzbach/Sieg gegründete Sportverein
            führt den Namen „Spiel- und Sportgemeinschaft Etzbach e.V.“. Er ist
            Mitglied des Sportbundes Rheinland e.V. und der zuständigen
            Landesfachverbände. Die Vereinsfarben sind schwarz-weiß. Der Verein
            hat seinen Sitz in Etzbach/Sieg. Er ist in das Vereinsregister beim
            Amtsgericht Montabaur eingetragen.
            <br />
            <br />
            <b>2.</b> Der Verein verfolgt ausschließlich und unmittelbar
            gemeinnützige Zwecke im Sinne der Abgabenordnung. Zweck des Vereins
            ist die Förderung des Sports. Der Verein ist selbstlos tätig; er
            verfolgt keine wirtschaftlichen Zwecke. Mittel des Vereins dürfen
            nur für die satzungsmäßigen Zwecke verwendet werden. Die Mitglieder
            erhalten keine Zuwendungen aus den Mitteln des Vereins. Es darf
            keine Person durch Ausgaben, die dem Zweck des Vereins fremd sind,
            oder durch unverhältnismäßig hohe Kostenvergütungen begünstigt
            werden.
          </p>
          <h4 className="sub-FooterLinkPage-text padding-20">
            § 2 – Erwerb der Mitgliedschaft
          </h4>
          <p className="padding-40">
            <b>1.</b> Mitglied des Vereins kann jede natürliche Person werden.
            <br />
            <br />
            <b>2.</b> Über die Aufnahme entscheidet nach schriftlichem Antrag
            der Vorstand. Bei Minderjährigen ist der Aufnahmeantrag durch die
            gesetzlichen Vertreter zu stellen.
            <br />
            <br />
            <b>3.</b> Die Mitgliederversammlung (Jahreshauptversammlung) kann
            verdienten Vereinsmitgliedern die Ehrenmitgliedschaft verleihen.
          </p>
          <h4 className="sub-FooterLinkPage-text padding-20">
            § 3 – Beendigung der Mitgliedschaft
          </h4>
          <p className="padding-40">
            <b>1.</b> Die Mitgliedschaft erlischt durch Austritt, Tod,
            Ausschluss oder Auflösung des Vereins. Die Austrittserklärung ist
            schriftlich an den Vorstand zu richten.
            <br />
            <br />
            <b>2.</b> Der Austritt ist nur zum Schluss eines
            Kalendervierteljahres unter Einhaltung einer Frist von 6 Wochen
            zulässig.
            <br />
            <br />
            <b>3.</b> Ein Mitglied kann aus folgenden Gründen, nach vorheriger
            Anhörung, vom Vorstand aus dem Verein ausgeschlossen werden: <br />
            <br />
            <b>a)</b> erhebliche Nichterfüllung satzungsmäßiger Verpflichtungen
            oder grobe Missachtung von Anordnungen der Organe des Vereins,
            <br />
            <b>b)</b> Nichtzahlung von Beiträgen trotz Mahnung,
            <br />
            <b>c)</b> schwerer Verstoß gegen die Interessen des Vereins oder
            grobe unsportliche Handlungen.
            <br />
            <br />
            <b>3.</b> Der Bescheid über den Ausschluss ist dem Mitglied per
            Einschreibebrief zuzustellen.
          </p>
          <h4 className="sub-FooterLinkPage-text padding-20">§ 4 – Beiträge</h4>
          <p className="padding-40">
            <b>1.</b> Von den ordentlichen Mitgliedern werden Beiträge erhoben.
            Die Höhe der Beiträge und deren Fälligkeit werden von der
            Mitgliederversammlung festgelegt.
            <br />
            <br />
            <b>2.</b> Ehrenmitglieder sind von der Beitragspflicht befreit, sie
            haben ansonsten die gleichen Rechte wie ordentliche Mitglieder.
          </p>
          <h4 className="sub-FooterLinkPage-text padding-20">
            § 5 – Stimmrecht und Wählbarkeit
          </h4>
          <p className="padding-40">
            <b>1.</b> Stimmberechtigt sind alle Mitglieder ab dem vollendeten
            16. Lebensjahr. Jüngere Mitglieder können an der
            Mitgliederversammlung als nicht stimmberechtigte Mitglieder
            teilnehmen.
            <br />
            <br />
            <b>2.</b> Bei der Wahl der Jugendleiter haben alle jugendlichen
            Mitglieder Stimmrecht. Als Jugendliche gelten alle Mitglieder bis
            zum vollendeten 25. Lebensjahr.
            <br />
            <br />
            <b>3.</b> Wählbar sind Mitglieder ab dem vollendeten 18. Lebensjahr.
          </p>
          <h4 className="sub-FooterLinkPage-text padding-20">
            § 6 – Organe des Vereins
          </h4>
          <p className="padding-40">
            Organe des Vereins sind der Vorstand und die Mitgliederversammlung.
          </p>
          <h4 className="sub-FooterLinkPage-text padding-20">§ 7 – Vorstand</h4>
          <p className="padding-40">
            <b>1.</b> Der Gesamtvorstand des Vereins besteht aus:
            <br />
            <br />
            <b>a)</b> dem/der ersten Vorsitzenden
            <br />
            <b>b)</b> dem/der stellvertretenden Vorsitzenden
            <br />
            <b>c)</b> dem/der Kassierer/in
            <br />
            <b>d)</b> dem/der stellvertretenden Kassierer/in
            <br />
            <b>e)</b> dem/der Schriftführer/in
            <br />
            <b>f)</b> dem/der stellvertretenden Schriftführer/in
            <br />
            <b>g)</b> dem/der Pressewart/in
            <br />
            <b>h)</b> dem Jugendleiter
            <br />
            <b>i)</b> der Jugendleiterin
            <br />
            <b>j)</b> den Abteilungsleitern/Abteilungsleiterinnen sowie
            <br />
            <b>k)</b> bis zu 2 Beisitzern/Beisitzerinnen
            <br />
            <b>2.</b> Vorstand im Sinne des § 26 BGB sind der Vorsitzende und
            sein Stellvertreter. Sie vertreten den Verein gerichtlich und
            außergerichtlich. Jeder von ihnen ist allein vertretungsberechtigt.
            <br />
            <br />
            <b>3.</b> Zu den Aufgaben des Vorstands gehören:
            <br />
            <br />
            <b>a)</b> die Durchführung der Beschlüsse der Mitgliederversammlung
            und die Behandlung von Anregungen,
            <br />
            <b>b)</b> die Bewilligung von Ausgaben,
            <br />
            <b>c)</b> Aufnahme, Ausschluss und Maßregelung von Mitgliedern,
            <br />
            <b>d)</b> alle Maßnahmen, die für die Leitung des Vereins und die
            Durchführung des sportlichen Betriebs notwendig sind.
            <br />
            <br />
            <b>4.</b> Sitzungen des Vorstands werden durch den ersten
            Vorsitzenden oder dessen Stellvertreter schriftlich oder per E-Mail
            unter Angabe der Tagesordnung einberufen.
            <br />
            <br />
            <b>5.</b> Der Vorstand ist beschlussfähig, wenn mindestens die
            Hälfte seiner Mitglieder anwesend ist. Bei Stimmengleichheit
            entscheidet die Stimme des Vorsitzenden. Sollte dieser nicht
            anwesend sein, entscheidet die Stimme seines Stellvertreters.
            <br />
            <br />
            <b>6.</b> Über die Beschlüsse des Vorstandes ist ein Protokoll
            anzufertigen, welches vom Vorsitzenden und dem Schriftführer zu
            unterzeichnen ist.
          </p>
          <h4 className="sub-FooterLinkPage-text padding-20">
            § 8 – Die Mitgliederversammlung
          </h4>
          <p className="padding-40">
            <b>1.</b> Oberstes Organ des Vereins ist die Mitgliederversammlung.
            <br />
            <br />
            <b>2.</b> Eine ordentliche Mitgliederversammlung
            (Jahreshauptversammlung) findet einmal im Jahr statt. Der Vorstand
            ist zur Einberufung einer außerordentlichen Mitgliederversammlung
            verpflichtet, wenn mindestens ein Drittel der Mitglieder dies
            schriftlich unter Angabe von Gründen verlangt.
            <br />
            <br />
            <b>3.</b> Die Mitgliederversammlung wird vom Vorstand unter
            Einhaltung einer Frist von drei Wochen schriftlich und unter Angabe
            der Tagesordnung einberufen. Die Einberufung erfolgt durch
            Veröffentlichung der Einladung im Mitteilungsblatt der
            Verbandsgemeinde Hamm sowie auf der Internetseite des Vereins.
            <br />
            <br />
            Die Tagesordnung muss folgende Punkte enthalten:
            <br />
            <br />
            <b>a)</b> Bericht des Vorstandes,
            <br />
            <b>b)</b> Kassenbericht und Bericht der Kassenprüfer,
            <br />
            <b>c)</b> Entlastung des Vorstandes,
            <br />
            <b>d)</b> Wahlen, soweit diese erforderlich sind,
            <br />
            <b>e)</b> Beschlussfassung über vorliegende Anträge.
            <br />
            <br />
            <b>4.</b> Die Tagesordnung ist zu ergänzen, wenn dies ein Mitglied
            bis spätestens eine Woche vor dem angesetzten Termin schriftlich
            beantragt und entsprechend begründet. Die Ergänzung ist zu Beginn
            der Versammlung bekannt zu machen. Dringlichkeitsanträge dürfen nur
            behandelt werden, wenn die anwesenden Mitglieder mit einer zwei
            Drittel Mehrheit beschließen, dass sie als Tagesordnungspunkte
            aufgenommen werden. Ein Dringlichkeitsantrag auf Satzungsänderung
            ist unzulässig.
            <br />
            <br />
            <b>5.</b> Versammlungsleiter ist der Vorsitzende. In dessen
            Verhinderungsfall übernimmt der zweite Vorsitzende die
            Versammlungsleitung.
            <br />
            <br />
            <b>6.</b> Die Mitgliederversammlung ist ohne Rücksicht auf die Zahl
            der erschienenen Mitglieder beschlussfähig.
            <br />
            <br />
            <b>7.</b> Beschlüsse werden mit einfacher Mehrheit der anwesenden
            stimmberechtigten Mitglieder gefasst. Stimmenthaltungen und
            ungültige Stimmen bleiben außer Betracht. Bei Stimmengleichheit
            entscheidet die Stimme des Vorsitzenden. Sollte dieser nicht
            anwesend sein, entscheidet die Stimme seines Stellvertreters.
            <br />
            <br />
            <b>8.</b> Zur Änderung der Satzung und des Vereinszwecks sowie der
            Auflösung des Vereins ist eine Mehrheit von zwei Drittel der
            anwesenden Mitglieder erforderlich.
            <br />
            <br />
            <b>9.</b> Geheime Abstimmung erfolgt nur, wenn mindestens drei
            stimmberechtigte Mitglieder dies beantragen.
            <br />
            <br />
            <b>10.</b> Über die Beschlüsse der Mitgliederversammlung ist ein
            Protokoll anzufertigen, das vom Versammlungsleiter und dem
            Schriftführer zu unterzeichnen ist.
          </p>
          <h4 className="sub-FooterLinkPage-text padding-20">
            § 9 – Abteilungen
          </h4>
          <p className="padding-40">
            <b>1.</b> Für die im Verein betriebenen Sportarten bestehen
            Abteilungen. Neue Abteilungen werden im Bedarfsfall durch Beschluss
            des Vorstandes gegründet.
            <br />
            <br />
            <b>2.</b> Die Abteilung wird durch den Abteilungsleiter geführt.
            <br />
            <br />
            <b>3.</b> Die Abteilungsleiter werden von der Mitgliederversammlung
            gewählt.
          </p>
          <h4 className="sub-FooterLinkPage-text padding-20">
            § 10 – Wahlen zum Vorstand
          </h4>
          <p className="padding-40">
            <b>1.</b> Der Vorstand wird von der Mitgliederversammlung gewählt.
            Vorstandsmitglieder können nur Mitglieder des Vereins werden.
            <br />
            <br />
            <b>2.</b> Der Jugendleiter und die Jugendleiterin werden in einer
            gesondert einzuberufenden Jugendversammlung gewählt. Die Wiederwahl
            ist zulässig. Die Wahl bedarf der Bestätigung der
            Mitgliederversammlung. Findet keine Jugendversammlung statt, so
            werden der Jugendleiter und die Jugendleiterin von der
            Mitgliederversammlung gewählt.
            <br />
            <br />
            <b>3.</b> Die Mitglieder des Vorstandes werden für die Dauer von
            zwei Jahren gewählt. Ein Vorstandsmitglied bleibt bis zu einer
            Neuwahl im Amt. Die Wiederwahl ist zulässig.
            <br />
            <br />
            <b>4.</b> Scheidet ein Vorstandsmitglied vorzeitig aus seinem Amt
            aus, so ist der Gesamtvorstand berechtigt, ein neues Mitglied
            kommissarisch bis zur nächsten Mitgliederversammlung zu berufen.
          </p>
          <h4 className="sub-FooterLinkPage-text padding-20">
            § 11 – Kassenprüfung
          </h4>
          <p className="padding-40">
            <b>1.</b> Die Kasse des Vereins wird in jedem Jahr durch zwei von
            der Mitgliederversammlung gewählte Kassenprüfer geprüft. Die
            Kassenprüfer werden für die Dauer von zwei Jahren gewählt.
            <br />
            <br />
            <b>2.</b> Die Kassenprüfer erstatten der Mitgliederversammlung einen
            Prüfbericht und beantragen bei ordnungsgemäßer Führung der
            Kassengeschäfte die Entlastung des Kassierers sowie des Vorstandes.
          </p>
          <h4 className="sub-FooterLinkPage-text padding-20">
            § 12 – Auflösung des Vereins
          </h4>
          <p className="padding-40">
            <b>1.</b> Die Auflösung des Vereins kann nur in einer
            außerordentlichen Mitgliederversammlung beschlossen werden. Auf der
            Tagesordnung dieser Versammlung darf nur der Punkt „Auflösung des
            Vereins“ stehen.
            <br />
            <br />
            <b>2.</b> Die Einberufung einer solchen Mitgliederversammlung darf
            nur erfolgen, wenn es
            <br />
            <br />
            <b>a)</b> der Vorstand mit einer Mehrheit von drei Viertel aller
            seiner Mitglieder beschlossen hat, oder
            <br />
            <b>b)</b> von zwei Drittel der stimmberechtigten Mitglieder des
            Vereins schriftlich beantragt wurde.
            <br />
            <br />
            <b>3.</b> Die Versammlung ist beschlussfähig, wenn mindestens die
            Hälfte der stimmberechtigten Mitglieder anwesend ist. Die Auflösung
            kann nur mit einer Mehrheit von zwei Dritteln der erschienenen
            stimmberechtigten Mitglieder beschlossen werden. Die Abstimmung ist
            namentlich vorzunehmen.
            <br />
            <br />
            <b>4.</b> Sollten bei der ersten Versammlung weniger als die Hälfte
            der stimmberechtigten Mitglieder anwesend sein, ist eine zweite
            Versammlung einzuberufen, welche dann mit einer Mehrheit von zwei
            Dritteln der anwesenden stimmberechtigten Mitglieder beschlussfähig
            ist.
            <br />
            <br />
            <b>5.</b> Die Frist zwischen einer ersten, nicht beschlussfähigen
            Versammlung und der zweiten Versammlung muss mindestens 8 Tage und
            darf höchstens 4 Wochen betragen.
            <br />
            <br />
            <b>6.</b> Bei Auflösung des Vereins oder Wegfall seines bisherigen
            Zwecks fällt das Vermögen an die Ortsgemeinde Etzbach. Die
            Ortsgemeinde Etzbach darf das ihr zufallende Vermögen nur für
            gemeinnützige Zwecke im Rahmen der Sportförderung verwenden.
            <br />
            <br />
            Die vorstehende Satzung gilt in der Fassung des Beschlusses der
            Mitgliederversammlung (Jahreshauptversammlung) vom <b>24.09.2020</b>
            .
          </p>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Satzung;
