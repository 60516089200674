import React, { useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "../../index.css";
import "./FooterLinks.css";

function Cookies() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="App">
      <Header />
      <main>
        <div className="FooterLinkPageWrapper">
          <h2 className="padding-20">Cookies</h2>
          <h4 className="sub-FooterLinkPage-text padding-20">
            Was sind Cookies?
          </h4>
          <p className="padding-20">
            Wir kennen „Cookies“ (dt. „Plätzchen“) vor allem als Datenpakete,
            die von Webbrowsern und Internetseiten erzeugt werden, um
            individuelle Nutzerdaten zu speichern. Ursprünglich waren sie unter
            dem Namen „Magic Cookies“ bekannt: Der Begriff bezeichnet sämtliche
            generischen Datenpakete, die zwischen Computer-Applikationen
            ausgetauscht werden. Mit dem Internet kamen schließlich in Form von
            HTTP-Cookies jene Datenpakete hinzu, mithilfe derer Webanwendungen
            personenbezogene Daten sammeln, um beispielsweise Login-Daten,
            Surfverhalten, Einstellungen und Aktionen in Webapplikationen (wie
            den „Warenkörben“ in Onlineshops) zu speichern.
            <br />
            <br />
            Grob zusammengefasst: Eine Website erkennt anhand des Cookies, wer
            sie gerade besucht, und kann sich dadurch in gewissem Rahmen an die
            Nutzerbedürfnisse anpassen. Die Datenspeicherung durch Cookies hat
            also durchaus einen spürbaren Effekt auf den User – zudem spielen
            Cookies inzwischen auch im Online-Marketing eine wichtige Rolle.
          </p>
          <h4 className="sub-FooterLinkPage-text padding-20">
            Nutzen wir Cookies?
          </h4>
          <p className="padding-20">
            Wir verzichten ausdrücklich auf das Erheben & Speichern
            personenbezogener Daten & nutzen aus diesem Grund auch keine
            Cookies.
          </p>
          <h4 className="sub-FooterLinkPage-text padding-20">
            Weitere Informationen zu Cookies
          </h4>
          <p className="padding-20">
            Weitere Informationen zu Cookies findest du{" "}
            <a href="https://www.ionos.de/digitalguide/hosting/hosting-technik/was-sind-cookies/">
              hier
            </a>
            .
          </p>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Cookies;
