import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import "../Home/Home.css";
import PlaceholderImage from "../Home/TestImage.jpg";

function News() {
  const data = useSelector((state) => state.NewsStore.NewsStore);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="App">
      <Header />
      <main>
        <div className="news-start news-page">
          <div className="Headline-and-Button">
            <h2>News</h2>
          </div>
          <div className="News-Wrapper-Home">
            {data.map((post) => (
              <div key={post.sys.id} className="News-Home-Wrapper">
                <div className="News-Home-Left">
                  {post.fields.image ? (
                    <img
                      className="smallImage"
                      src={post.fields.image.fields.file.url}
                      alt="Test"
                    />
                  ) : (
                    <img
                      src={PlaceholderImage}
                      alt="Platzhalter"
                      className="smallImage"
                    />
                  )}
                </div>
                <div className="News-Home-Right">
                  <h2>{post.fields.headline}</h2>
                  <p className="News-Home-Text">{post.fields.text}</p>
                  <Link
                    to={"/news/" + post.sys.id}
                    className="All-Games-Link-Screen i-l-b"
                  >
                    <p>Mehr lesen</p>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default News;
