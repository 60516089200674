import React, { useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "../../index.css";
import "./FooterLinks.css";

function Impressum() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="App">
      <Header />
      <main>
        <div className="FooterLinkPageWrapper">
          <h2 className="padding-20">Impressum</h2>
          <h4 className="sub-FooterLinkPage-text padding-20">
            Angaben gemäß § 5 TMG
          </h4>
          <p className="padding-20">
            Spiel- u. Sportgemeinschaft Etzbach e.V. <br />
            Drosselweg 9 <br />
            57539 Etzbach
            <br />
            Vereinsregister: VR10252 <br />
            Registergericht: Amtsgericht Montabaur
          </p>
          <h4 className="sub-FooterLinkPage-text padding-20">
            Vertreten durch den Vorstand:
          </h4>
          <p className="padding-20">
            Julian Schröder <br />
            Sarah Gerhards <br />
            Janina Pattberg <br />
            Rolf Grün
          </p>
          <h4 className="sub-FooterLinkPage-text padding-20">Kontakt</h4>
          <p className="padding-20">
            E-Mail:{" "}
            <a href="mailto:ssg-etzbach@web.de" className="E-Mail">
              ssg-etzbach@web.de
            </a>
            <br />
            <br />
            Wir sind nicht bereit oder verpflichtet, an
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.
          </p>
          <h4 className="sub-FooterLinkPage-text padding-20">
            Haftung für Inhalte
          </h4>
          <p className="padding-20">
            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
            auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
            §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
            verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
            überwachen oder nach Umständen zu forschen, die auf eine
            rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung
            oder Sperrung der Nutzung von Informationen nach den allgemeinen
            Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist
            jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
            Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
            Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
          </p>
          <h4 className="sub-FooterLinkPage-text padding-20">
            Haftung für Links
          </h4>
          <p className="padding-20">
            Unser Angebot enthält Links zu externen Websites Dritter, auf deren
            Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
            fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
            verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
            der Seiten verantwortlich. Die verlinkten Seiten wurden zum
            Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
            Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
            erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten
            Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung
            nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
            derartige Links umgehend entfernen.
          </p>
          <h4 className="sub-FooterLinkPage-text padding-20">Urheberrecht</h4>
          <p className="padding-20">
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
            diesen Seiten unterliegen dem deutschen Urheberrecht. Die
            Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
            Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
            schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
            Downloads und Kopien dieser Seite sind nur für den privaten, nicht
            kommerziellen Gebrauch gestattet.
            <br />
            <br />
            Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
            wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
            werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
            trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten
            wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
            Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
          </p>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Impressum;
