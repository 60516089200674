import React from "react";
import Header from "../Header/Header";
import { client } from "../../contentful";
import Footer from "../Footer/Footer";
import TestImage from "./TestImage.jpg";
import "../../index.css";
import "../../App.css";
import "./TeamsPage.css";
import { useState, useEffect } from "react";

function Senioren() {
  const [activeTeam, setActiveTeam] = useState([]);

  useEffect(() => {
    client
      .getEntries({
        content_type: "mannschaften",
        "sys.id": "4uKyJv1cet6AgpdZnrfLjn",
      })
      .then((res) => {
        setActiveTeam(res);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="App">
      <Header />
      {activeTeam.length !== 0 ? (
        <main>
          <div className="TeamsMainImage">
            {activeTeam.items[0]?.fields?.teamImage?.fields?.file?.url ? (
              <img
                className="TeamImageDetails"
                src={activeTeam.items[0].fields.teamImage.fields.file.url}
                alt="Team Foto"
              />
            ) : (
              <img
                className="TeamImageDetails"
                src={TestImage}
                alt="Team Foto"
              />
            )}
          </div>
          <div className="main-wrapper">
            <div className="HalfWrapper">
              {activeTeam.items[0].fields.teamName && (
                <h2 className="paddingBottom40">
                  {activeTeam.items[0].fields.teamName}
                </h2>
              )}
            </div>
            <div className="HalfWrapper">
              {activeTeam.items[0].fields.teamText ? (
                <div className="team-text p-class text-justify">
                  {activeTeam.items[0].fields.teamText}
                </div>
              ) : (
                <div className="team-text p-class text-justify">
                  Es wurde kein Text hinterlegt.
                </div>
              )}
            </div>
          </div>
          <div className="Trennlinie"></div>
          <div className="main-wrapper margin-80">
            {activeTeam.items[0].fields.trainer && (
              <div className="HalfWrapper">
                <h2 className="paddingBottom40">Trainer:</h2>
                <p className="padding10">
                  {activeTeam.items[0].fields.trainer}
                </p>
                {activeTeam.items[0].fields.eMail && (
                  <a
                    href={"mailto:" + activeTeam.items[0].fields.eMail}
                    className="p-class E-Mail color-dark"
                  >
                    {activeTeam.items[0].fields.eMail}
                  </a>
                )}
              </div>
            )}
            <div className="HalfWrapper">
              <h2 className="paddingBottom40">Trainingszeiten:</h2>
              {activeTeam.items[0].fields.training1 && (
                <p className="padding10">
                  {activeTeam.items[0].fields.training1}
                </p>
              )}
              {activeTeam.items[0].fields.training2 && (
                <p>{activeTeam.items[0].fields.training2}</p>
              )}
              <div className="LinkButtonWrapper">
                {activeTeam.items[0].fields.spielplan ? (
                  <a
                    target="_blank"
                    href={activeTeam.items[0].fields.spielplan}
                    rel="noreferrer"
                    className="LinkButton"
                  >
                    Spielplan
                  </a>
                ) : (
                  ""
                )}
                {activeTeam.items[0].fields.tabelle ? (
                  <a
                    target="_blank"
                    href={activeTeam.items[0].fields.tabelle}
                    rel="noreferrer"
                    className="LinkButton"
                  >
                    Tabelle
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </main>
      ) : (
        <main style={{ textAlign: "center" }}>
          <p>Inhalt wird geladen ...</p>
        </main>
      )}
      <Footer />
    </div>
  );
}

export default Senioren;
