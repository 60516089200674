const initialState = {
  StartSlides: [],
};

export function StartSlides(state = initialState, action) {
  if (action.type === "setHomeSlideshows") {
    return { ...state, StartSlides: action.payload };
  }
  return state;
}
