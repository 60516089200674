const initialState = {
  mannschaftenStore: [],
};

export function mannschaftenStore(state = initialState, action) {
  if (action.type === "setMannschaften") {
    return { ...state, mannschaftenStore: action.payload };
  }
  return state;
}
