import { createStore, combineReducers } from "redux";
import { HomeNews } from "./mainStore";
import { StartGamesStore } from "./StartGamesStore";
import { StartSlides } from "./StartSlidesStore";
import { mannschaftenStore } from "./mannschaftenStore";
import { NewsStore } from "./NewsStore";
import { VorstandStore } from "./VorstandStore";

export default createStore(
  combineReducers({
    HomeNews: HomeNews,
    StartGamesStore: StartGamesStore,
    StartSlides: StartSlides,
    mannschaftenStore: mannschaftenStore,
    NewsStore: NewsStore,
    VorstandStore: VorstandStore,
  })
);
