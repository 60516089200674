import React from "react";
// import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Header from "../Header/Header";
import { client } from "../../contentful";
import Footer from "../Footer/Footer";
import "../../index.css";
import "../../App.css";
import "../TeamsPage/TeamsPage.css";
import { useState, useEffect } from "react";
import PlaceholderImage from "../Home/TestImage.jpg";
import "./News.css";

function NewsPage() {
  const [activeNews, setActiveNews] = useState([]);
  // const { team } = useParams();
  const { id } = useParams();

  useEffect(() => {
    client
      .getEntries({
        content_type: "news",
        "sys.id": id,
      })
      .then((res) => {
        setActiveNews(res);
      });
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="App">
      <Header />
      {activeNews.length !== 0 ? (
        <main>
          <div className="TeamsMainImage">
            {activeNews.items[0]?.fields?.image?.fields?.file?.url ? (
              <img
                className="smallImage"
                src={activeNews.items[0].fields.image.fields.file.url}
                alt="Test"
              />
            ) : (
              <img className="smallImage" src={PlaceholderImage} alt="Test" />
            )}
          </div>
          <div className="MainNewsWrapper">
            <h2>{activeNews.items[0].fields.headline}</h2>
            <div className="news-text p-class text-justify margin-40">
              {activeNews.items[0].fields.text}
            </div>
            <div className="p-class margin-20">
              <b>{"Author: " + activeNews.items[0].fields.author}</b>
            </div>
            <div className="p-class">
              {"Stand: " + activeNews.items[0].fields.date}
            </div>
          </div>
        </main>
      ) : (
        <main style={{ textAlign: "center" }}>Loading ... </main>
      )}
      <Footer />
    </div>
  );
}

export default NewsPage;
