import React from "react";
import { Link } from "react-router-dom";
import "../../App.css";
import "../Home/Home.css";
import "./Footer.css";
import DatenschutzIcon from "./lock_white_24dp.svg";
import ImpressumIcon from "./info_white_24dp.svg";
import CoronaIcon from "./coronavirus_white_24dp.svg";
import CookieIcon from "./cookie_white_24dp.svg";

function Footer() {
  return (
    <div className="Footer">
      <div className="innerFooter">
        <div className="Half-Footer">
          <h3 className="headline-padding">Kontakt:</h3>
          <p className="white-text">
            Spiel- und Sportgemeinschaft Etzbach e.V. <br /> Julian Schröder{" "}
            <br /> Drosselweg 9 <br />
            57539 Etzbach
          </p>
          <p className="white-text padding-20">
            E-Mail:{" "}
            <a href="mailto:julian.schroeder@t-online.de" className="E-Mail">
              julian.schroeder@t-online.de
            </a>
          </p>
          <h3 className="headline-padding">Verantwortlich für den Inhalt:</h3>
          <p className="white-text">Rolf Grün</p>
          <p className="white-text">
            E-Mail:{" "}
            <a href="mailto:rolf-gruen@t-online.de" className="E-Mail">
              rolf-gruen@t-online.de
            </a>
          </p>
        </div>
        <div className="Half-Footer">
          <h3 className="headline-padding">Links:</h3>
          <Link to="/datenschutz" className="FooterLink">
            <div className="FooterLinkWrapper">
              <img src={DatenschutzIcon} alt="Datenschutz" />
            </div>
            <div className="FooterLinkWrapper">
              <p>Datenschutz</p>
            </div>
          </Link>
          <Link to="/impressum" className="FooterLink">
            <div className="FooterLinkWrapper">
              <img src={ImpressumIcon} alt="Datenschutz" />
            </div>
            <div className="FooterLinkWrapper">
              <p>Impressum</p>
            </div>
          </Link>
          <Link to="/corona" className="FooterLink">
            <div className="FooterLinkWrapper">
              <img src={CoronaIcon} alt="Datenschutz" />
            </div>
            <div className="FooterLinkWrapper">
              <p>Corona Schutzverordnung</p>
            </div>
          </Link>
          <Link to="/cookies" className="FooterLink">
            <div className="FooterLinkWrapper">
              <img src={CookieIcon} alt="Datenschutz" />
            </div>
            <div className="FooterLinkWrapper">
              <p>Cookies</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Footer;
