import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { client } from "../../contentful";
import FileDownload from "./file_download_black_24dp.svg";
import "./Downloads.css";
import "../../index.css";
import "../FooterLinks/FooterLinks.css";

function Downloads() {
  const [downloadData, setDownloadData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    client
      .getEntries({
        content_type: "downloads",
      })
      .then((res) => {
        setDownloadData(res.items);
      });
  }, []);

  return (
    <div className="App">
      <Header />
      <main>
        <div className="FooterLinkPageWrapper">
          <h2 className="padding-20">Downloads</h2>
          {downloadData &&
            downloadData.map((download, index) => (
              <a
                className={
                  download.fields?.pdfDatei?.fields?.file?.url
                    ? "Downloads_Link"
                    : "Downloads_Link_Disabled"
                }
                href={
                  download.fields?.pdfDatei?.fields?.file?.url
                    ? download.fields.pdfDatei.fields.file.url
                    : ""
                }
                download
                key={index}
              >
                <div className="Download_Link_Inner">
                  {download.fields?.pdfDatei?.fields?.file?.url ? (
                    <h4 className="sub-FooterLinkPage-text">
                      {download.fields?.dokumentName
                        ? download.fields.dokumentName
                        : "Unbenanntes Dokument"}
                    </h4>
                  ) : (
                    <h4 className="sub-FooterLinkPage-text">
                      {download.fields?.dokumentName
                        ? download.fields.dokumentName + " (PDF-Datei fehlt!)"
                        : "Unbenanntes Dokument (PDF-Datei fehlt!)"}
                    </h4>
                  )}
                </div>
                <div className="Download_Link_Inner">
                  <p>
                    {download.fields?.stand
                      ? "Stand: " + download.fields.stand
                      : "Stand: Nicht bekannt"}
                  </p>
                </div>
                <div className="Download_Link_Inner">
                  <img src={FileDownload} alt="" />
                </div>
              </a>
            ))}
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Downloads;
