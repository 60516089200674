import SSGLogo from "./SSG-Logo.png";
import MenuIcon from "./menu.svg";
import CloseMenuIcon from "./close.svg";
import { Link } from "react-router-dom";
import "./Header.css";
import { useState } from "react";
import nextImage from "./navigate_next_black_24dp.svg";

function Header() {
  const [menuClosed, setMenuClosed] = useState(true);
  const [vereinSub, setVereinSub] = useState(false);
  const [teamSub, setTeamSub] = useState(false);
  const [gymnastikSub, setGymnastikSub] = useState(false);
  const [kidsSub, setKidsSub] = useState(false);

  function toggleMenu() {
    setMenuClosed(!menuClosed);
  }

  function toggleVereinSub() {
    setVereinSub(!vereinSub);
    setTeamSub(false);
    setGymnastikSub(false);
    setKidsSub(false);
  }

  function toggleTeamSub() {
    setTeamSub(!teamSub);
    setVereinSub(false);
    setGymnastikSub(false);
    setKidsSub(false);
  }

  function toggleGymnastikSub() {
    setGymnastikSub(!gymnastikSub);
    setTeamSub(false);
    setVereinSub(false);
    setKidsSub(false);
  }

  function toggleKidsSub() {
    setKidsSub(!kidsSub);
    setGymnastikSub(false);
    setTeamSub(false);
    setVereinSub(false);
  }

  return (
    <>
      {/* <div className="overflow-wrapper"> */}
      <div
        className={
          menuClosed
            ? "mobile-navigation"
            : "mobile-navigation mobile-navigation-open"
        }
      >
        <ul>
          <li>
            <Link to="/" className="Headline-Link-mobile">
              Start
            </Link>
          </li>
          <li>
            <Link to="/news" className="Headline-Link-mobile">
              News
            </Link>
          </li>
          <li className="Headline-li-mobile">
            <p
              className="Headline-Link-mobile"
              onClick={() => toggleVereinSub()}
            >
              Verein
              <img
                src={nextImage}
                alt="Weitere Infos"
                className={
                  vereinSub
                    ? "Headline-Link-Image-Open"
                    : "Headline-Link-Image-Closed"
                }
              />
            </p>
            <ul
              className={vereinSub ? "subMenu-mobile-open" : "subMenu-mobile"}
            >
              <li>
                <Link className="SubLink-mobile" to="/vorstand">
                  Vorstand
                </Link>
              </li>
              <li>
                <Link className="SubLink-mobile" to="/satzung">
                  Satzung
                </Link>
              </li>
              <li>
                <Link className="SubLink-mobile" to="/downloads">
                  Downloads
                </Link>
              </li>
            </ul>
          </li>
          <li className="Headline-li-mobile">
            <p className="Headline-Link-mobile" onClick={() => toggleTeamSub()}>
              Volleyball
              <img
                src={nextImage}
                alt="Weitere Infos"
                className={
                  teamSub
                    ? "Headline-Link-Image-Open"
                    : "Headline-Link-Image-Closed"
                }
              />
            </p>
            <ul
              className={
                teamSub
                  ? "subMenu-mobile subMenu-mobile-open"
                  : "subMenu-mobile"
              }
            >
              <li>
                <Link
                  className="SubLink-mobile"
                  to="/teams/damen-1
                    "
                >
                  1. Damen
                </Link>
              </li>
              <li>
                <Link className="SubLink-mobile" to="/teams/damen-2">
                  2. Damen
                </Link>
              </li>
              <li>
                <Link className="SubLink-mobile" to="/teams/herren-1">
                  1. Herren
                </Link>
              </li>
              <li>
                <Link className="SubLink-mobile" to="/teams/beach">
                  Beach
                </Link>
              </li>
              <li>
                <Link
                  className="SubLink-mobile"
                  to="/teams/hobby
                    "
                >
                  Hobby-Volleyballer
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/termine" className="Headline-Link-mobile">
              Termine
            </Link>
          </li>
          <li className="Headline-li-mobile">
            <p
              className="Headline-Link-mobile"
              onClick={() => toggleGymnastikSub()}
            >
              Gymnastik
              <img
                src={nextImage}
                alt="Weitere Infos"
                className={
                  gymnastikSub
                    ? "Headline-Link-Image-Open"
                    : "Headline-Link-Image-Closed"
                }
              />
            </p>
            <ul
              className={
                gymnastikSub
                  ? "subMenu-mobile subMenu-mobile-open"
                  : "subMenu-mobile"
              }
            >
              <li>
                <Link className="SubLink-mobile" to="/gymnastik/gruppe-1">
                  Gruppe 1
                </Link>
              </li>
              <li>
                <Link className="SubLink-mobile" to="/gymnastik/gruppe-2">
                  Gruppe 2
                </Link>
              </li>
              <li>
                <Link className="SubLink-mobile" to="/gymnastik/senioren">
                  Senioren Gymnastik
                </Link>
              </li>
              <li>
                <Link className="SubLink-mobile" to="/gymnastik/kursangebot">
                  Kursangebot
                </Link>
              </li>
            </ul>
          </li>

          <li className="Headline-li-mobile">
            <p className="Headline-Link-mobile" onClick={() => toggleKidsSub()}>
              Jugend
              <img
                src={nextImage}
                alt="Weitere Infos"
                className={
                  kidsSub
                    ? "Headline-Link-Image-Open"
                    : "Headline-Link-Image-Closed"
                }
              />
            </p>
            <ul
              className={
                kidsSub
                  ? "subMenu-mobile subMenu-mobile-open"
                  : "subMenu-mobile"
              }
            >
              <li>
                <Link className="SubLink-mobile" to="/kids/kids-1">
                  1-2 Jahre
                </Link>
              </li>
              <li>
                <Link className="SubLink-mobile" to="/kids/kids-2">
                  3-4 Jahre
                </Link>
              </li>
              <li>
                <Link className="SubLink-mobile" to="/kids/kids-3">
                  5-6 Jahre
                </Link>
              </li>
              <li>
                <Link className="SubLink-mobile" to="/kids/tanzen-1">
                  Tanzen 3-5 Jahre
                </Link>
              </li>
              <li>
                <Link className="SubLink-mobile" to="/kids/tanzen-2">
                  Tanzen 6-9 Jahre
                </Link>
              </li>
              <li>
                <Link className="SubLink-mobile" to="/kids/ballschule">
                  Ballschule
                </Link>
              </li>
              <li>
                <Link className="SubLink-mobile" to="/kids/anfaenger">
                  Volleyball Anfänger
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      {/* </div> */}
      <div className="Header">
        <div className="innerHeader">
          <Link to="/" className="flex-button">
            <img src={SSGLogo} alt="SSG Etzbach Logo" className="Logo-Size" />
          </Link>
          <button className="Menu-Button" onClick={toggleMenu}>
            {menuClosed ? (
              <img src={MenuIcon} alt="Menu" className="Menu-Size" />
            ) : (
              <img src={CloseMenuIcon} alt="Close Menu" className="Menu-Size" />
            )}
          </button>
          <div className="screen-navbar">
            <ul>
              <li>
                <Link to="/" className="Headline-Link">
                  Start
                </Link>
              </li>
              <li>
                <Link to="/news" className="Headline-Link">
                  News
                </Link>
              </li>
              <li className="Headline-li">
                <Link to="/vorstand" className="Headline-Link">
                  Verein
                </Link>
                <ul className="subMenu">
                  <li>
                    <Link className="SubLink" to="/vorstand">
                      Vorstand
                    </Link>
                  </li>
                  <li>
                    <Link className="SubLink" to="/satzung">
                      Satzung
                    </Link>
                  </li>
                  <li>
                    <Link className="SubLink" to="/downloads">
                      Downloads
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="Headline-li">
                <Link to="/teams/damen-1" className="Headline-Link">
                  Volleyball
                </Link>
                <ul className="subMenu">
                  <li>
                    <Link
                      className="SubLink"
                      to="/teams/damen-1
                    "
                    >
                      1. Damen
                    </Link>
                  </li>
                  <li>
                    <Link className="SubLink" to="/teams/damen-2">
                      2. Damen
                    </Link>
                  </li>
                  <li>
                    <Link className="SubLink" to="/teams/herren-1">
                      1. Herren
                    </Link>
                  </li>
                  <li>
                    <Link className="SubLink" to="/teams/beach">
                      Beach
                    </Link>
                  </li>
                  <li>
                    <Link className="SubLink" to="/teams/hobby">
                      Hobby-Volleyballer
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/termine" className="Headline-Link">
                  Termine
                </Link>
              </li>
              <li className="Headline-li">
                <Link to="/gymnastik/gruppe-1" className="Headline-Link">
                  Gymnastik
                </Link>
                <ul className="subMenu">
                  <li>
                    <Link className="SubLink" to="/gymnastik/gruppe-1">
                      Gruppe 1
                    </Link>
                  </li>
                  <li>
                    <Link className="SubLink" to="/gymnastik/gruppe-2">
                      Gruppe 2
                    </Link>
                  </li>
                  <li>
                    <Link className="SubLink" to="/gymnastik/senioren">
                      Senioren Gymnastik
                    </Link>
                  </li>
                  <li>
                    <Link className="SubLink" to="/gymnastik/kursangebot">
                      Kursangebot
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="Headline-li">
                <Link to="/kids/kids-1" className="Headline-Link">
                  Jugend
                </Link>
                <ul className="subMenu">
                  <li>
                    <Link className="SubLink" to="/kids/kids-1">
                      1-2 Jahre
                    </Link>
                  </li>
                  <li>
                    <Link className="SubLink" to="/kids/kids-2">
                      3-4 Jahre
                    </Link>
                  </li>
                  <li>
                    <Link className="SubLink" to="/kids/kids-3">
                      5-6 Jahre
                    </Link>
                  </li>
                  <li>
                    <Link className="SubLink" to="/kids/tanzen-1">
                      Tanzen 3-5 Jahre
                    </Link>
                  </li>
                  <li>
                    <Link className="SubLink" to="/kids/tanzen-2">
                      Tanzen 6-9 Jahre
                    </Link>
                  </li>
                  <li>
                    <Link className="SubLink" to="/kids/ballschule">
                      Ballschule
                    </Link>
                  </li>
                  <li>
                    <Link className="SubLink" to="/kids/anfaenger">
                      Volleyball Anfänger
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
