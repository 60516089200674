import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Header from "../Header/Header";
import StartGames from "../StartGames/StartGames";
import StarOutlineImage from "./star_outline_black_24dp.svg";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import "./Home.css";

import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";

function Home() {
  const data = useSelector((state) => state.HomeNews.HomeNews);
  const slideData = useSelector((state) => state.StartSlides.StartSlides);
  const AutoplaySlider = withAutoplay(AwesomeSlider);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="App">
      <Header />
      <main>
        <AutoplaySlider
          className="half-width"
          play={true}
          cancelOnInteraction={true}
          interval={3000}
        >
          {slideData.map((slide, index) => (
            <div key={index} className="slide">
              <img
                className="slide-image"
                src={slide.fields.image.fields.file.url}
                alt="Test"
              />
            </div>
          ))}
        </AutoplaySlider>
        <StartGames />

        <div className="news-start">
          <div className="Headline-and-Button">
            <h2>Aktuelles</h2>
            <Link to="/news" className="All-Games-Link-Screen">
              <img src={StarOutlineImage} alt="Star" className="Link-Image" />
              <p>Aktuelles</p>
            </Link>
          </div>
          <div className="News-Wrapper-Home">
            {data.map((post) => (
              <div key={post.sys.id} className="News-Home-Wrapper">
                <div className="News-Home-Left">
                  <img
                    className="smallImage"
                    src={post.fields.image.fields.file.url}
                    alt="Test"
                  />
                </div>
                <div className="News-Home-Right">
                  <h2>{post.fields.headline}</h2>
                  <p className="News-Home-Text">{post.fields.text}</p>
                  <Link
                    to={post.fields.homeNewsLink}
                    className="All-Games-Link-Screen i-l-b"
                  >
                    <p>Mehr lesen</p>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Home;
