const initialState = {
  HomeNews: [],
};

export function HomeNews(state = initialState, action) {
  if (action.type === "setHomeNews") {
    return { ...state, HomeNews: action.payload };
  }
  return state;
}
