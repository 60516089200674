const initialState = {
  StartGamesStore: [],
};

export function StartGamesStore(state = initialState, action) {
  if (action.type === "setStartGames") {
    return { ...state, StartGamesStore: action.payload };
  }
  return state;
}
