import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "../../index.css";
import "../../App.css";
import "./Vorstand.css";
import PhoneIcon from "./call_white_24dp.svg";
import MailIcon from "./email_white_24dp.svg";

function Vorstand() {
  const Vorstand = useSelector((state) => state.VorstandStore.VorstandStore);

  console.log(Vorstand);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="App">
      <Header />
      <main>
        <div className="wrapper">
          <h2 className="padding-bottom">Vorstand</h2>
          <div className="grid-wrapper">
            {Vorstand.map((item, index) => (
              <div key={index} className="Vorstand-Wrapper">
                <div className="img-wrapper">
                  {item.fields.foto?.fields?.file?.url && (
                    <img
                      className="img-vorstand"
                      src={item.fields.foto.fields.file.url}
                      alt="Vorstand"
                    />
                  )}
                </div>
                <h3 className="Name-Headline color-dark">{item.fields.name}</h3>
                <p>{item.fields.aufgabe}</p>
                {item.fields.telefonnummer ? (
                  <a
                    className="phone-wrapper p-class"
                    href={"tel:" + item.fields.telefonnummer}
                    rel="noreferrer"
                  >
                    <div className="icon-wrapper">
                      <img src={PhoneIcon} alt="Telefon" />
                    </div>
                    {item.fields.telefonnummer}
                  </a>
                ) : (
                  <div className="phone-wrapper p-class">
                    <div className="icon-wrapper">
                      <img src={PhoneIcon} alt="Telefon" />
                    </div>
                    Keine Nummer verfügbar
                  </div>
                )}
                {item.fields.eMail ? (
                  <a
                    target="_blank"
                    className="phone-wrapper p-class"
                    href={"mailto:" + item.fields.eMail}
                    rel="noreferrer"
                  >
                    <div className="icon-wrapper">
                      <img src={MailIcon} alt="Telefon" />
                    </div>
                    {item.fields.eMail}
                  </a>
                ) : (
                  <div className="phone-wrapper p-class">
                    <div className="icon-wrapper">
                      <img src={PhoneIcon} alt="Telefon" />
                    </div>
                    Keine Mailadresse verfügbar
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Vorstand;
