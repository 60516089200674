const initialState = {
  NewsStore: [],
};

export function NewsStore(state = initialState, action) {
  if (action.type === "setNews") {
    return { ...state, NewsStore: action.payload };
  }
  return state;
}
