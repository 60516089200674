import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { client } from "../../contentful";
import TerminImage from "./event_black_24dp.svg";
import "./Termine.css";
import "../../index.css";
import "../FooterLinks/FooterLinks.css";

function Termine() {
  const [terminData, setTerminData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    client
      .getEntries({
        content_type: "termine",
        order: "fields.datumUhrzeit",
      })
      .then((res) => {
        setTerminData(res.items);
      });
  }, []);

  console.log(terminData);

  return (
    <div className="App">
      <Header />
      <main>
        <div className="FooterLinkPageWrapper">
          <h2 className="padding-20">Termine</h2>
          <div className="Termin-Wrapper">
            {terminData &&
              terminData.map((termin, index) => (
                <div className="Game-Wrapper" key={index}>
                  <div className="Top-Wrapper">
                    <div className="Match-Location">
                      <img
                        className="Location-Image"
                        src={TerminImage}
                        alt="Volleyball"
                      />
                      <p className="Location-Headline">Event</p>
                    </div>
                    <div className="Date-Wrapper">
                      <p className="Big-Date">{termin.fields.day}</p>
                      <p className="Month-Date">
                        {termin.fields.monat + " " + termin.fields.year}
                      </p>
                    </div>
                  </div>
                  <div className="Bottom-Wrapper-Termine">
                    <p className="Location-Headline">
                      {termin.fields.headline}
                    </p>
                    <p className="p-class">{termin.fields.location}</p>
                  </div>
                  {termin.fields?.link && termin.fields?.pdf ? (
                    termin.fields?.link && (
                      <div className="Bottom-Left-Right-Termine">
                        <a
                          href={termin.fields.link}
                          className="Link-Wrapper-Termine p-class"
                        >
                          Zum Artikel
                        </a>
                      </div>
                    )
                  ) : !termin.fields?.pdf && !termin.fields?.link ? (
                    <div className="Bottom-Left-Right-Termine">
                      <a
                        href={termin.fields.link}
                        className="Link-Wrapper-Termine p-class"
                      >
                        -
                      </a>
                    </div>
                  ) : (
                    <div className="Bottom-Left-Right-Termine">
                      <a
                        href={termin.fields.pdf.fields.file.url}
                        target="_blank"
                        className="Link-Wrapper-Termine p-class"
                        rel="noreferrer"
                        download
                      >
                        PDF anzeigen
                      </a>
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Termine;
