import { useSelector } from "react-redux";
import "./StartGames.css";
import VolleyballImage from "./volleyball.svg";
import { Link } from "react-router-dom";
import PlaceholderIcon from "./image_not_supported_black_24dp.svg";

function StartGames() {
  const StartGames = useSelector(
    (state) => state.StartGamesStore.StartGamesStore
  );

  return (
    <div className="StartGames">
      <div className="Headline-and-Button">
        <h2>Bevorstehende Spiele</h2>
        <Link to="/termine" className="All-Games-Link-Screen">
          <img src={VolleyballImage} alt="Volleyball" className="Link-Image" />
          <p>Alle Termine</p>
        </Link>
      </div>
      <div className="flex-container-Game">
        {StartGames.map((post) => (
          <div className="Game-Wrapper" key={post.sys.id}>
            <div className="Top-Wrapper">
              <div className="Match-Location">
                <img
                  className="Location-Image"
                  src={VolleyballImage}
                  alt="Volleyball"
                />
                <p className="Location-Headline">{post.fields.mannschaft}</p>
              </div>
              <div className="Date-Wrapper">
                <p className="Big-Date">
                  {post.fields.day < 10
                    ? "0" + post.fields.day
                    : post.fields.day}
                </p>
                <p className="Month-Date">{post.fields.monthYear}</p>
              </div>
            </div>
            <div className="Bottom-Wrapper">
              <div className="Team-Game-Wrapper HomeTeam">
                <img
                  src={
                    post.fields.homeTeam.fields
                      ? post.fields.homeTeam.fields.file.url
                      : PlaceholderIcon
                  }
                  alt="Home Team"
                />
              </div>
              <div className="versus">:</div>
              <div className="Team-Game-Wrapper AwayTeam">
                <img
                  src={
                    post.fields.visitingTeam.fields
                      ? post.fields.visitingTeam.fields.file.url
                      : PlaceholderIcon
                  }
                  alt="Visiting Team"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="Trennlinie"></div>
    </div>
  );
}

export default StartGames;
